<template>
  <v-stepper v-model="$route.meta.step">
    <v-stepper-header>
      <v-stepper-step
        :editable="checkStepEditable(1)"
        :complete="$route.meta.step > 1"
        step="1"
      >
        Cadastro
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :editable="checkStepEditable(2)"
        :complete="$route.meta.step > 2"
        step="2"
      >
        Empresas
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :editable="checkStepEditable(3)"
        :complete="$route.meta.step > 3"
        step="3"
      >
        Público Alvo
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :editable="checkStepEditable(4)"
        :complete="$route.meta.step > 4"
        step="4"
      >
        Público x Meta
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :editable="checkStepEditable(5)" step="5">
        Resumo
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-container>
        <router-view ref="view"></router-view>
        <v-btn
          color="primary"
          outlined
          v-show="$route.meta.backStep"
          @click="$router.go(-1)"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          @click="nextStepRouter()"
          v-show="$route.meta.nextStep"
          class="ml-3"
          :disabled="validBtnNext"
        >
          Avançar
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-container>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AssistenteEstapasCadastro",
  data() {
    return {
      step: 1,
      lastStep: 1,
      menssageButton: "",
      title: "Campanha finalizada com sucesso!",
      menssage:
        "O cadastro de sua campanha foi finalizado e entrará em processo de análise aguardando a aprovação de um responsável",
      dinamicConfirm: {
        register: {
          title: "Cadastrar campanha",
          text: "Deseja cadastrar a campanha?",
          message: "Cadastro criado com sucesso"
        },
        edit: {
          title: "Editar campanha",
          text: "Deseja alterar os dados da campanha?",
          message: "Cadastro atualizado com sucesso"
        }
      },
      disabledBtnCadastroCampanha: true,
      campanhaEdited: false
    };
  },
  computed: {
    ...mapGetters({
      getStatusEmpresa: "campanha/getStatusEmpresa",
      getStatusPublicoAlvo: "campanha/getStatusPublicoAlvo",
      getStatusMetas: "campanha/getStatusMetas"
    }),

    validBtnNext() {
      return !this[this.$route.meta.validBtnNextStep];
    }
  },

  methods: {
    nextStepRouter() {
      if (this.$route.meta.nextStep === "empresas") {
        return this.$router.push(
          `${this.$route.params.idCampanha}/${this.$route.meta.nextStep}`
        );
      }
      return this.$router.push(this.$route.meta.nextStep);
    },
    pushCadastro() {
      this.$router.push(`/campanhas/cadastro/${this.$route.params.idCampanha}`);
    },

    checkStepEditable(step) {
      return step <= this.lastStep;
    }
  }
};
</script>
