<template>
  <v-container class="container-card">
    <assistente-estapas-cadastro />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AssistenteEstapasCadastro from "@/components/campanha/cadastro/AssistenteEstapasCadastro";
export default {
  name: "CadastroCampanha",

  components: {
    AssistenteEstapasCadastro
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Cadastro Campanha");
  }
};
</script>

<style></style>
